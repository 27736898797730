import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { UserExportSettings } from '../schemas/general';

export enum Theme {
	LIGHT = 'light',
	DARK = 'dark',
	COLOR_BLIND = 'colorBlind',
}

type TUserSettingsStoreType = {
	theme: Theme;
	export: UserExportSettings;
	isMenuExpanded: boolean;
	toggleMenu: () => void;
	setTheme: (theme: Theme) => void;
	setExport: (n: UserExportSettings) => void;
};

export const useUserSettings = create(
	persist(
		immer<TUserSettingsStoreType>((set) => ({
			theme: Theme.LIGHT,
			isMenuExpanded: true,
			export: {
				rounding: -1,
			},
			toggleMenu: () =>
				set((state) => {
					state.isMenuExpanded = !state.isMenuExpanded;
				}),
			setTheme: (theme: Theme) =>
				set((state) => {
					state.theme = theme;
				}),
			setExport: (n: UserExportSettings) =>
				set((state) => {
					state.export = n;
				}),
		})),
		{
			name: 'user-settings-storage',
			version: 1,
		}
	)
);
