import { QueryClientProvider } from '@tanstack/react-query';
import Axios from 'axios';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Suspense, lazy, useEffect } from 'react';
import { useUserSettings } from './app/state/user-settings.store';
import { UserDataProvider } from './app/utils/UserDataProvider';
import { QUERY_CLIENT } from './app/utils/query-client';
import Soccerball from './assets/Soccerball.svg';
import { API_BASE_URL } from './config/environment';
import './index.css';

dayjs.extend(localizedFormat);

const LOGIN_URL = `${API_BASE_URL}v1/auth/oidc/login?redirectAfterLoginUrl=${window.location.href}`;

Axios.defaults.baseURL = API_BASE_URL;
Axios.defaults.withCredentials = true;
Axios.defaults.maxRedirects = 0;
Axios.defaults.validateStatus = function (status) {
	return status >= 200 && status < 300;
};

//Redirect to Login upon receiving 401
Axios.interceptors.response.use(null, function (error) {
	if (error?.response?.status === 401) {
		window.location.href = LOGIN_URL;
		return;
	} else {
		throw error;
	}
});

const Root = lazy(() => import('./app/root'));

export function App() {
	const { theme } = useUserSettings((state) => ({
		theme: state.theme,
	}));

	useEffect(() => {
		document.querySelector('html')?.setAttribute('data-theme', theme);
	}, [theme]);

	return (
		<Suspense fallback={<FallbackComponent />}>
			<QueryClientProvider client={QUERY_CLIENT}>
				<UserDataProvider loginUrl={LOGIN_URL}>
					<Root />
				</UserDataProvider>
			</QueryClientProvider>
		</Suspense>
	);
}

function FallbackComponent() {
	return (
		<div
			className="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen gap-4 transition-colors bg-base-200"
			style={{
				background: 'radial-gradient(oklch(var(--b1)),oklch(var(--b1)), oklch(var(--p)/0.3))',
			}}
		>
			<div className="animate-spin-ball">
				<img src={Soccerball} style={{ width: '10vw', height: '10vh' }} />
			</div>
			<div className="flex gap-1">
				<span className="text-2xl font-light">Loading...</span>
			</div>
		</div>
	);
}
