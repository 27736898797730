import { UserInfoDto } from '@slabs/lib-api';
import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';

type UserInfoStoreState = {
	userInfo: Required<UserInfoDto>;
	setUserInfo: (s: UserInfoDto) => void;
};

export const useUserData = create(
	immer<UserInfoStoreState>((set) => ({
		userInfo: {
			id: '',
			avatarUrl: '',
			email: '',
			isStrykerlabsAdmin: false,
			displayName: '',
			allowedTenants: [],
			allowedEnvironments: [],
			selectedTenantFeatureflags: [],
			selectedTenantPermissions: [],
			selectedEnvironmentPermissions: [],
			defaultTenantId: '',
			defaultEnvironmentId: '',
			selectedTenant: '',
			selectedEnvironment: '',
		},
		setUserInfo: (value: UserInfoDto) =>
			set((state) => {
				state.userInfo = {
					...value,
					defaultTenantId: value.defaultTenantId || '',
					defaultEnvironmentId: value.defaultEnvironmentId || '',
					selectedTenant: value.selectedTenant || '',
					selectedEnvironment: value.selectedEnvironment || '',
				};
			}),
	}))
);
