import { useAuthControllerGetUserInfoSuspense } from '@slabs/lib-api/src/query/api';
import React, { useEffect } from 'react';
import { useUserData } from '../state/user-data';

// This is a simple wrapper just so that we can trigger the upstream suspense
export const UserDataProvider = ({ children, loginUrl }: React.PropsWithChildren & { loginUrl: string }) => {
	const { data, error } = useAuthControllerGetUserInfoSuspense({ query: { retry: 1 } });
	const [setUserInfo, userInfo] = useUserData((state) => [state.setUserInfo, state.userInfo]);

	useEffect(() => {
		if (error) {
			window.location.href = loginUrl;
		}
	}, [error, loginUrl]);

	useEffect(() => {
		if (data) {
			setUserInfo(data.data);
		}
	}, [data, setUserInfo]);

	return <> {userInfo.id ? children : null}</>;
};
